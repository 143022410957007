<template>
  <div>
    <label>
      <span v-if="label">{{ label }}</span>
    </label>
    <woot-thumbnail
      v-if="src"
      size="80px"
      :src="src"
      :username="usernameAvatar"
    />
    <div v-if="src && deleteAvatar" class="avatar-delete-btn mb-4">
      <woot-button
        color-scheme="alert"
        variant="hollow"
        size="tiny"
        type="button"
        @click="onAvatarDelete"
      >
        {{ $t('INBOX_MGMT.DELETE.AVATAR_DELETE_BUTTON_TEXT') }}
      </woot-button>
    </div>
    <label class="system-file-upload mb-4">
      <button type="button" @click="triggerFileInput">{{ $t('INBOX_MGMT.UPLOAD.CHOOSE_FILE') }}</button>
      <input
        id="file"
        ref="file"
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
        @change="handleImageUpload"
      />
      <span>{{ fileLabel }}</span>
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    usernameAvatar: {
      type: String,
      default: '',
    },
    deleteAvatar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileLabel: this.$t('INBOX_MGMT.UPLOAD.NO_FILE_CHOSEN')
    };
  },
  methods: {
    handleImageUpload(event) {
      const [file] = event.target.files;

      this.fileLabel = file ? file.name : this.$t('INBOX_MGMT.UPLOAD.NO_FILE_CHOSEN');

      this.$emit('change', {
        file,
        url: file ? URL.createObjectURL(file) : null,
      });
    },
    triggerFileInput() {
      this.$refs.file.click();
    },
    onAvatarDelete() {
      this.$refs.file.value = null;
      this.fileLabel = this.$t('INBOX_MGMT.UPLOAD.NO_FILE_CHOSEN');
      this.$emit('onAvatarDelete');
    },
  },
};
</script>

<style lang="scss" scoped>
.system-file-upload input[type="file"] {
  display: none;
}

.system-file-upload {
  display: inline-block;
  cursor: pointer;
}

.system-file-upload button {
  @apply bg-[#f0f0f0] dark:bg-[#6b6b6b] leading-[1.15];
  padding: 8px 8px;
  margin: 0 0 0 8px;
  border: 1px solid rgb(38, 41, 43);
}

#app.rtl .system-file-upload button {
  margin: 0 0 0 8px;
}
#app:not(.rtl) .system-file-upload button {
  margin: 0 8px 0 0;
}

</style>
