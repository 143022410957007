// import ar from './locale/ar';
// import ca from './locale/ca';
// import cs from './locale/cs';
// import da from './locale/da';
// import de from './locale/de';
// import el from './locale/el';
import en from './locale/en';
// import es from './locale/es';
import fa from './locale/fa';
// import fi from './locale/fi';
// import fr from './locale/fr';
// import he from './locale/he';
// import hi from './locale/hi';
// import hu from './locale/hu';
// import id from './locale/id';
// import it from './locale/it';
// import ja from './locale/ja';
// import ko from './locale/ko';
// import lv from './locale/lv';
// import ml from './locale/ml';
// import nl from './locale/nl';
// import no from './locale/no';
// import pl from './locale/pl';
// import pt from './locale/pt';
// import pt_BR from './locale/pt_BR';
// import ro from './locale/ro';
// import ru from './locale/ru';
// import sk from './locale/sk';
// import sr from './locale/sr';
// import sv from './locale/sv';
// import ta from './locale/ta';
// import th from './locale/th';
// import tr from './locale/tr';
// import uk from './locale/uk';
// import vi from './locale/vi';
// import zh_CN from './locale/zh_CN';
// import zh_TW from './locale/zh_TW';
// import is from './locale/is';
// import lt from './locale/lt';

const replacements = [
  // Array of replacements
  { placeholder: '__systemName__', value: 'Mebot' },
  { placeholder: '__systemUrl__', value: 'adanai.ir' },
  // Add more replacements here
];

function replacePlaceholder(obj, placeholder, replacement) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].replace(new RegExp(placeholder, 'g'), replacement);
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      replacePlaceholder(obj[key], placeholder, replacement);
    }
  }
  return obj;
}

const locales = {
  // ar,
  // ca,
  // cs,
  // da,
  // de,
  // el,
  en,
  // es,
  fa,
  // fi,
  // fr,
  // he,
  // hi,
  // hu,
  // id,
  // it,
  // ja,
  // ko,
  // ml,
  // lv,
  // nl,
  // no,
  // pl,
  // pt_BR,
  // pt,
  // ro,
  // ru,
  // sk,
  // sr,
  // sv,
  // ta,
  // th,
  // tr,
  // uk,
  // vi,
  // zh_CN,
  // zh_TW,
  // is,
  // lt,
};

const messages = {};
// eslint-disable-next-line no-restricted-syntax
for (const localeCode in locales) {
  let translatedLocale = JSON.parse(JSON.stringify(locales[localeCode])); // Deep clone to avoid modifying original locales
  // eslint-disable-next-line no-restricted-syntax
  for (const replacement of replacements) {
    translatedLocale = replacePlaceholder(
      translatedLocale,
      replacement.placeholder,
      replacement.value
    );
  }
  messages[localeCode] = translatedLocale;
}
export default {
  ...messages,
};
